<template>
  <div>
    <div class="overlay title">
      <h2>{{group}}</h2>
    </div>
    <div class="overlay title" v-if="subTitle">
      <h3>{{subTitle}}</h3>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MapTitle',
    props: {
      group: {
        required: true,
        type: String,
      },
      subTitle: {
        required: false,
        type: String,
      },
    },
  };
</script>

<style lang="scss">
.overlay.title {
  top: 14px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  h2, h3{
    line-height: 32px;
    margin: 0;
  }

  h2 {
    font-size: 18px;
  }

  h3{
    font-size: 16px;
    text-align: right;
    margin-right: 208px;
    font-weight: normal;
  }

  
  // width:100px;
  // height: 32px;
  // background-color: blue;
}
</style>