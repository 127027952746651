import { MapElementFactory } from 'vue2-google-maps';

// reference: https://developers.google.com/maps/documentation/javascript/reference/kml
export default MapElementFactory({
  name: 'kmlLayer',
  ctr: () => google.maps.KmlLayer,
  //// The following is optional, but necessary if the constructor takes multiple arguments
  //// e.g. for GroundOverlay
  // ctrArgs: (options, otherProps) => [options],
  events: ['click', 'defaultviewport_changed', 'status_changed'],

  // Mapped Props will automatically set up
  //   this.$watch('propertyName', (v) => instance.setPropertyName(v))
  //
  // If you specify `twoWay`, then it also sets up:
  //   google.maps.event.addListener(instance, 'propertyName_changed', () => {
  //     this.$emit('propertyName_changed', instance.getPropertyName())
  //   })
  //
  // If you specify `noBind`, then neither will be set up. You should manually
  // create your watchers in `afterCreate()`.
  mappedProps: {
    preserveViewport: {
      type: Boolean,
      default: true,
      noBind: true,
    },
    suppressInfoWindows: {
      type: Boolean,
      noBind: true,
    },
    screenOverlays: { type: Boolean },
    url: { type: String },
    zIndex: { type: Number },
    //// If you have a property that comes with a `_changed` event,
    //// you can specify `twoWay` to automatically bind the event, e.g. Map's `zoom`:
    // zoom: {type: Number, twoWay: true}
  },
  // Any other properties you want to bind. Note: Must be in Object notation
  props: {},
  // Actions you want to perform before creating the object instance using the
  // provided constructor (for example, you can modify the `options` object).
  // If you return a promise, execution will suspend until the promise resolves
  beforeCreate(options) {},
  // Actions to perform after creating the object instance.
});