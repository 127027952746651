<template>
  <div class="overlay save">
    <el-button v-if="cancelText != null" class="main-actions cancel" type="info" @click="$emit('cancel')">{{cancelText}}</el-button>
    <el-button class="main-actions" type="primary" @click="$emit('save')" :disabled="saveDisables">{{text}}</el-button>
  </div>
</template>

<script>
  export default {
    name: 'SaveButton',
    props: {
      text: {
        required: false,
        type: String,
        default: "Save",
      },
      cancelText: {
        required: false,
        type: String,
        default: null,
      },
      saveDisables: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
.overlay.save {
  top: auto;
  bottom: 30px;
  left: auto;
  right: 63px;

  button.cancel {
    margin-right: 20px;
  }
  button {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3), 0 2px 2px 0 rgba(0, 0, 0, 0.4);
  }
}
</style>