<template>
  <div>
    <default-google-map
      ref="map"
      :center="map.center"
      :zoom="map.zoom"
      :map-type-id="map.typeId"
      @center_changed="mapCenterChange"
      @click="click"
      @idle="onIdle"
      @tilesloaded="tilesLoaded"
      @bounds_changed="updateRange"
      @zoom_changed="onZoom"
    >
      <kml-layer
        v-for="(kmlFile, index) in kml"
        :key="`kml-${index}`"
        :url="kmlFile"
      />
      <nest
        v-for="(position, index) in nest"
        :key="`nest-${index}`"
        :position="position"
        @click="
          event =>
            flyAreaGuidedClick(event, {
              type: 'point',
              source: 'nests',
              id: position.id,
              name: position.name
            })
        "
      />
      <camera
        v-for="(position, index) in camera"
        :key="`camera-${index}`"
        :position="position"
      />
      <weather
        v-for="(position, index) in weather"
        :key="`weather-${index}`"
        :position="position"
        @mouseover="hoverWeatherIndex = index"
        @mouseout="hoverWeatherIndex = -1"
      >
        <gmap-info-window
          :opened="hoverWeatherIndex === index"
          @closeclick="hoverWeatherIndex = -1"
        >
          <div>
            <span class="station_name">{{ selectedStation.name }}</span>
            <div
              v-if="showWeatherData"
              class="map-weather-statistics"
            >
              <div class="label">
                {{ $t("dashboard.temperature") }}
              </div>
              <div class="value">
                {{ getTransTmp(selectedStation.temp) | roundNumber(0, "--") }}{{ tmpUnitHint }}
              </div>
              <div class="label">
                {{ $t("dashboard.wind") }}
              </div>
              <div class="value">
                {{ selectedStation.direction }} {{ getTransSpeed(selectedStation.wind) | roundNumber(1, "--") }}{{ speedUnitHint }}
              </div>
            </div>
          </div>
        </gmap-info-window>
      </weather>
      <fly-area
        ref="flyArea"
        :path="fence"
        @click="flyAreaGuidedClick"
        @dblclick="e => $emit('double-click', e)"
      />
      <no-fly-zone
        v-for="(zone, index) in map.noFlyZone"
        v-if="zone.type == 'no_fly'"
        ref="no_fly"
        :key="`no-fly-${zone.id}`"
        :paths="zone.polygons"
        :index="index"
        @click="click"
      />
      <obstacle-zone
        v-for="(zone, index) in map.noFlyZone"
        v-if="zone.type == 'obstacle'"
        ref="obstacle"
        :key="`obstacle-${zone.id}`"
        :paths="zone.polygons"
        :index="index"
        @dblclick="e => $emit('double-click', e)"
        @click="flyAreaGuidedClick"
      />
      <altitude-limit-zone
        v-for="(zone, index) in map.noFlyZone"
        v-if="zone.type == 'altitude_limit'"
        ref="altitude_limit"
        :key="`altitude-limit-${zone.id}`"
        :paths="zone.polygons"
        :index="index"
        @dblclick="e => $emit('double-click', e)"
        @click="flyAreaGuidedClick"
      />
      <slot />
    </default-google-map>
    <operator
      :top="operatorTop"
      :menu-item="editMode ? ['map-mode', 'center', 'undo', 'clear'] : ['map-mode', 'center']"
      @click="operator"
    />
    <zoom-button
      @zoomin="map.zoom += 1"
      @zoomout="map.zoom -= 1"
    />
  </div>
</template>

<script>
import _config from 'config';
import { mapActions, mapGetters } from 'vuex';
import { GeographicShorter, calculBounds } from '@/utils/map.js';
import { WindDirection } from '@/utils/winddirections.js';
import { TmpMeasure, SpeedMeasure } from '@/utils/measure';

import DefaultGoogleMap from '@/components/GoogleMaps/DefaultGoogleMap.js';
import KmlLayer from '@/components/GoogleMaps/Native/KmlLayer.js';
import FlyArea from '@/components/GoogleMaps/FlyArea.js';
import Nest from '@/components/GoogleMaps/Nest.js';
import Camera from '@/components/GoogleMaps/Camera.js';
import Weather from '@/components/GoogleMaps/Weather.js';
import NoFlyZone from '@/components/GoogleMaps/NoFlyZone.js';
import ObstacleZone from '@/components/GoogleMaps/ObstacleZone.js';
import AltitudeLimitZone from '@/components/GoogleMaps/AltitudeLimitZone.js';
import Operator from '@/components/Maps/Operator.vue';
import ZoomButton from '@/components/Maps/ZoomButton.vue';
import NestApi from '@/services/api/domain/group/nest';
import CameraApi from '@/services/api/domain/group/camera';
import WeatherApi from '@/services/api/domain/group/weather';
import NoFlyZoneAPI from '@/services/api/domain/noFlyZone.js';

export default {
  name: 'FlightMap',
  components: {
    DefaultGoogleMap,
    KmlLayer,
    Operator,
    FlyArea,
    NoFlyZone,
    ObstacleZone,
    AltitudeLimitZone,
    Nest,
    Camera,
    Weather,
    ZoomButton,
  },
  props: {
    loadItems: {
      type: Array,
      default() {
        return ['fence', 'no_fly_zone', 'cameras', 'weather_stations', 'nests'];
      },
    },
    flyAreaClick: {
      required: false,
      type: Function,
      default: () => {},
    },
    flyAreaGuidedClick: {
      required: false,
      type: Function,
      default: () => {},
    },
    click: {
      required: false,
      type: Function,
      default: () => {},
    },
    editMode: {
      required: false,
      type: Boolean,
      default: true,
    },
    showNest: {
      type: Boolean,
      default: true,
    },
    showCamera: {
      type: Boolean,
      default: true,
    },
    showWeather: {
      type: Boolean,
      default: true,
    },
    showWeatherData: {
      type: Boolean,
      default: false,
    },
    nests: {
      default: null,
    },
    operatorTop: {},
  },
  data() {
    return {
      map: {
        center: {
          lat: 0,
          lng: 0,
        },
        typeId: 'roadmap',
        zoom: 17,
        noFlyZone: [],
        nest: [],
        camera: [],
        weather: [],
        activatePath: null,
        kml: {
          // 18: [
          //   'http://175.111.192.39:8082/kml/mosaic.kml'
          // ],
          // 19: [
          //   'http://175.111.192.39:8082/kml/19/438406/299396.kml',
          //   'http://175.111.192.39:8082/kml/19/438406/299397.kml',
          //   'http://175.111.192.39:8082/kml/19/438406/299398.kml',
          // ],
          18: [_config.kmlEndpoint],
        },
      },
      mapRange: [],
      tempBound: [],
      lastLoading: 0,
      hoverWeatherIndex: -1,
    };
  },
  computed: {
    ...mapGetters({
      group: 'user/group',
      mapCenter: 'map/centerGoogleMaps',
      weatherLatesetMessage: 'mqtt/weatherLatesetMessage',
    }),
    selectedStation() {
      const station = this.map.weather[this.hoverWeatherIndex];
      if (station) {
        const message = this.weatherLatesetMessage[station.id];
        return {
          name: station.name,
          temp: message && message.heartbeat ? message.heartbeat.temperature_outside : null,
          wind: message && message.heartbeat ? message.heartbeat.wind_speed : null,
          direction: message && message.heartbeat ? WindDirection(message.heartbeat.wind_direction) : '',
        };
      }
      return {};
    },
    nest() {
      if (this.showNest) {
        if (this.nests) {
          return this.nests;
        }
        return this.map.nest.map(GeographicShorter);
      }
      return [];
    },
    camera() {
      if (this.showCamera) {
        return this.map.camera.map(GeographicShorter);
      }
      return [];
    },
    weather() {
      if (this.showWeather) {
        return this.map.weather.map(GeographicShorter);
      }
      return [];
    },
    fence() {
      const loadFence = this.loadItems.indexOf('fence') >= 0;
      if (this.group.fence == null || !loadFence) {
        return null;
      }
      return this.group.fence.map(GeographicShorter);
    },
    kml() {
      if (Object.keys(this.map.kml) == 0) {
        return [];
      }

      const data = this.map.kml[this.map.zoom];
      if (data) {
        return data;
      }
      let offset = 0;
      while (true) {
        offset += 1;
        if (this.map.kml[this.map.zoom + offset]) {
          return this.map.kml[this.map.zoom + offset];
        }
        if (this.map.kml[this.map.zoom - offset]) {
          return this.map.kml[this.map.zoom - offset];
        }
      }
    },
    speedUnitHint() {
      return SpeedMeasure.unit();
    },
    tmpUnitHint() {
      return TmpMeasure.unit();
    },
  },
  watch: {
    async mapRange(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }

      if (this.loadItems.indexOf('no_fly_zone') === -1) {
        return;
      }

      try {
        const { data } = await NoFlyZoneAPI.getList(now);
        data.no_fly_zones = data.no_fly_zones.map((zone) => {
          zone.polygons = zone.polygons.map((polygon) => polygon.map(GeographicShorter));
          return zone;
        });
        this.map.noFlyZone = data.no_fly_zones;
      } catch (error) {
        this.$showFail(error);
      }
    },
  },
  created() {
    this.mapReset();
    this.map.center = GeographicShorter(this.mapCenter);
    const loadNest = this.loadItems.indexOf('nests') >= 0;
    const loadCamera = this.loadItems.indexOf('cameras') >= 0;
    const loadWeather = this.loadItems.indexOf('weather_stations') >= 0;

    if (loadNest) {
      NestApi.getAll(this.group.id)
        .then((response) => {
          this.map.nest = response.data.nests;
        })
        .catch(this.$showFail);
    }

    if (loadCamera) {
      CameraApi.getAll(this.group.id)
        .then((response) => {
          this.map.camera = response.data.cameras;
        })
        .catch(this.$showFail);
    }

    if (loadWeather) {
      WeatherApi.getAll(this.group.id)
        .then((response) => {
          this.map.weather = response.data.stations.filter((station) => station.inactive === false);
        })
        .catch(this.$showFail);
    }
  },
  methods: {
    ...mapActions({
      mapReset: 'map/reset',
      mapCenterChange: 'map/centerUpdateGoogleMaps',
      goGroupCenter: 'map/goGroupCenter',
    }),
    operator(name) {
      switch (name) {
        case 'map-mode':
          if (this.map.typeId == 'roadmap') {
            this.map.typeId = 'satellite';
          } else {
            this.map.typeId = 'roadmap';
          }
          break;
        case 'center':
          this.goGroupCenter();
          break;
        default:
          this.$emit('operator', name);
      }
    },
    onZoom(event) {
      this.map.zoom = event;
      this.$emit('zoom_changed');
    },
    onIdle() {
      this.lastLoading += 1;
      const now = this.lastLoading;
      setTimeout(() => {
        if (now === this.lastLoading) {
          this.mapRange = this.tempBound;
          if (this.lastLoading > 9999) {
            this.lastLoading = 1;
          }
        }
      }, 1000);
    },
    updateRange(bounds) {
      if (bounds == null) {
        return;
      }
      const northEast = bounds.getNorthEast();
      const southWest = bounds.getSouthWest();
      const round = 1e6;
      this.tempBound = [
        Math.floor(northEast.lat() * round) / round,
        Math.floor(northEast.lng() * round) / round,
        Math.ceil(southWest.lat() * round) / round,
        Math.ceil(southWest.lng() * round) / round,
      ];
    },
    positionInZone(latLng, zone) {
      return google.maps.geometry.poly.containsLocation(latLng, zone.$polygonObject);
    },
    findZonesInPosition(latLng) {
      const list = [];

      const types = ['no_fly', 'obstacle', 'altitude_limit'];
      for (const type of types) {
        for (const zoneIndex in this.$refs[type]) {
          const zoneVueObejct = this.$refs[type][zoneIndex];
          if (this.positionInZone(latLng, zoneVueObejct)) {
            list.push({
              ...this.map.noFlyZone[zoneVueObejct.index],
              type: 'polygon',
              source: 'no_fly_zones',
              zoneType: this.map.noFlyZone[zoneVueObejct.index].type,
            });
          }
        }
      }

      if (this.positionInZone(latLng, this.$refs.flyArea)) {
        list.push({
          type: 'polygon',
          source: 'fence',
        });
      }

      return list;
    },
    tilesLoaded() {
      const { fence } = this;
      if (this.tileLoaded == undefined && fence && fence.length > 0) {
        const boundcalculBounds = calculBounds(fence);
        this.$refs.map.fitBounds(boundcalculBounds);
      }
      this.tileLoaded = true;
    },
    getTransSpeed(value, digit = 1) {
      return SpeedMeasure.display(value, digit, 0);
    },
    getTransTmp(value, digit = 0) {
      return TmpMeasure.display(value, digit, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-weather-statistics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 4px;
  justify-items: center;

  .station_name {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .label {
    width: 100%;
    text-align: left;
  }
  .value {
    width: 100%;
    text-align: right;
  }
}
</style>
