import { Marker } from 'vue2-google-maps';
import { Icon } from '@/components/GoogleMaps/MarkerStyle.js';

const props = {
  draggable: {
    type: Boolean,
    twoWay: true,
    default: false,
  },
  icon: {
    twoWay: true,
    default: () => {
      return Icon.nest;
    },
  },
  zIndex: {
    twoWay: true,
    default: 1,
  },
};


export default {
  extends: Marker,
  props,
  name: 'Nest',
};